import { bluetoothFilters } from "./constants";
import { displayMsg, displayResult } from "./DisplayMsg";
import {
  bluetoothRequestCancelledMsg,
  bluetoothSecurityMsg,
  noBluetoothDeviceFoundMsg,
} from "./messages";
import { moveBar } from "./MoveBar";
export const testBluetooth = async (width) => {
  displayMsg(
    "Testing bluetooth capabilities.. please wait and select your device",
    "success"
  );
  try {
    const device = await navigator.bluetooth.requestDevice({
      filters: bluetoothFilters,
    });
  } catch (error) {
    let message = error.message;
    if (message === "User cancelled the requestDevice() chooser.") {
      message = bluetoothRequestCancelledMsg;
    } else if (
      message === "User or their enterprise policy has disabled Web Bluetooth."
    ) {
      message = bluetoothSecurityMsg;
    } else {
      message = noBluetoothDeviceFoundMsg;
    }
    displayResult(message);
  }
  moveBar(width);
};
