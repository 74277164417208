import { displayMsg, displayResult } from "./DisplayMsg";
import { moveBar } from "./MoveBar";
import { camBusyMsg, camPermissionDeniedMsg, noCamDeviceMsg } from "./messages";

const constraints = {
  video: true,
  audio: false,
};

export const testCam = async (width: number) => {
  displayMsg("Testing camera...", "success");
  let camResult = "";
  const mediaDevices = navigator.mediaDevices;

  try {
    const stream = await mediaDevices.getUserMedia(constraints);
    if (stream.getVideoTracks().length > 0) {
      stream.getVideoTracks().forEach((track) => {
        track.stop();
      });
    } else {
      camResult = noCamDeviceMsg;
      displayResult(camResult);
    }
  } catch (error: any) {
    if (error.name === "NotReadableError") {
      camResult = camBusyMsg;
    } else if (error.name === "NotAllowedError") {
      camResult = camPermissionDeniedMsg;
    } else {
      camResult = noCamDeviceMsg;
    }
    displayResult(camResult);
  }
  moveBar(width);
};
