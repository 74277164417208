//when the device is iOS but the browser is not WebBLE
//only webBLE works on iOS devices.
export const iosNotWebBleMsg = `
iOS devices are only compatible for remote patient monitoring using the webBLE or Bluefy browser. Please download WebBLE from <a target='_blank' href="https://apps.apple.com/us/app/webble/id1193531073">here</a> or Bluefy from <a target='_blank' href="https://apps.apple.com/us/app/bluefy-web-ble-browser/id1492822055">here</a>`;

//when the device is other OS but the browser is not chrome or Edge
//only Chrome/Edge work on other OS
export const otherOsNotChromeEdgeMsg = `
Your web browser is not compatible. Please use Microsoft Edge or Google Chrome browsers.`;

//when the Edge/Chrome version is less than 95
export const chromeEdgeMinVerMsg = `
Your browser is out of date. Please update to the latest version, with the minimum being Chrome 95 or Microsoft Edge 95. `;

//when no camera devices are found on the computer.
export const noCamDeviceMsg = `
No Usable camera was detected during the test. Please check access to your camera. For more information, click <a target='_blank' href="https://help.vcc.healthdirect.org.au/57493-category-3/issues-during-a-video-call">here</a>.`;

//when devices are being used by another app.
export const camBusyMsg = `
The camera could not be started. It could be in use by another application. Please check and try again.`;

export const micBusyMsg = `
The camera could not be started. It could be in use by another application. Please check and try again.`;

//when no mic devices are found on the computer
export const noMicDeviceMsg = `
No Microphone was detected during the test. Please check access to your microphone. For more information, click <a target='_blank' href="https://help.vcc.healthdirect.org.au/57493-category-3/issues-during-a-video-call">here</a>.`;

//when camera permissions are not allowed
export const camPermissionDeniedMsg = `
Camera permissions were denied. Please allow camera permissions to complete the test. Click <a target='_blank' href="https://help.vcc.healthdirect.org.au/57493-category-3/allow-camera-mic">here</a> for more information.`;

//when mic permissions are not allowed.
export const micPermissionDeniedMsg = `
Microphone permissions were denied. Please allow microphone permissions to complete the test. Click <a target='_blank' href="https://help.vcc.healthdirect.org.au/57493-category-3/allow-camera-mic">here</a> for more information`;

//the final message when something is not right
export const finalFailMsg = `
We have detected something is not right. There are issues that will prevent you from sharing your patient monitoring device into a video call consult. Please see below for more information.`;

//the final message when everything is okay
export const finalPassMsg = `
You can successfully use your patient monitoring device in the video call consult.`;

//when no bluetooth devices are found nearby
export const noBluetoothDeviceFoundMsg = `
No patient monitoring device was detected. Please turn on your monitoring device, restart the test and then select it.`;

//when user cancels the bluetooth connection prompt
export const bluetoothRequestCancelledMsg = `
No patient monitoring device was detected. Please turn on your monitoring device, restart the test and then select it.`;

//when the organisation has blocked web bluetooth on the browser
export const bluetoothSecurityMsg = `
Bluetooth devices are not allowed in this browser for security reasons. Please contact your IT department.`;

//when internet speed is less
export const lowBandwidthMsg = `
Our estimation showed that your internet connection is not stable. You might have some connection issues during the call.`;
