import { finalFailMsg, finalPassMsg } from "./messages";
import { moveBar } from "./MoveBar";

export const finalMsg = async () => {
  moveBar(100);
  const mainDisplay = document.getElementById("mainDisplay");
  const failResults = document.getElementsByClassName("Summary-card");
  const progressBar = document.getElementById("progressContainer");
  const icon = document.createElement("span");
  icon.className = "Final-icon";
  if (progressBar) {
    progressBar.innerHTML = "";
    progressBar.append(icon);
    if (failResults.length > 0) {
      icon.innerHTML = "&#9888;";
      const restartBtn = document.createElement("button");
      restartBtn.className = "Start-button";
      restartBtn.innerText = "Restart";
      restartBtn.addEventListener("click", () => {
        window.location.reload();
      });
      if (mainDisplay) {
        mainDisplay.append(restartBtn);
        mainDisplay.style.top = "20%";
      }
    } else {
      const finalMsgDiv = document.createElement("div");
      progressBar.append(finalMsgDiv);
      finalMsgDiv.className = "Final-msg";
      finalMsgDiv.innerHTML = finalPassMsg;
      icon.innerHTML = "&check;";
      icon.style.color = "green";
    }
  }
};
