import { contHtmlId } from "./constants";

export const displayResult = (result: string) => {
  if (result) {
    const container = document.getElementById(contHtmlId);
    const p = document.createElement("p");
    const icon = document.createElement("span");
    const span = document.createElement("span");
    span.innerHTML = result;
    icon.className = "Caution-yellow";
    icon.innerText = "✖";
    p.append(icon, span);
    p.className = "Summary-card";
    container?.append(p);
  }
};
export const displayMsg = (message: string, msgType: string) => {
  const displayMsg = document.getElementById("progressMsg") as HTMLElement;
  displayMsg.innerHTML = "";
  displayMsg.innerHTML = message;
  if (msgType === "success") {
    displayMsg.style.color = "green";
  } else {
    displayMsg.style.color = "red";
  }
  displayMsg.style.display = "block";
};
