import { displayMsg, displayResult } from "./DisplayMsg";
import { lowBandwidthMsg } from "./messages";
import { moveBar } from "./MoveBar";

export const testInternet = async (width: any) => {
  const imageAddr =
    "https://static.helpjuice.com/helpjuice_production/uploads/upload/image/5044/direct/1648005506728-1648005506728.png";
  const imageSize = 561901 * 8;
  let estSpeed = 0;

  displayMsg("Estimating your internet download speed...", "success");
  moveBar(width);

  const calculateSpeed = () =>
    new Promise((resolve, reject) => {
      var noCache = "?noCache=" + new Date().getTime();
      const startTime = new Date().getTime();
      const image = new Image();
      image.src = imageAddr + noCache;
      const timeoutId = setTimeout(() => {
        //do logic after 20seconds
        resolve(0.5);
      }, 5000);

      image.decode().then(() => {
        clearTimeout(timeoutId);
        const endTime = new Date().getTime();
        const dnldTime = (endTime - startTime) / 1000;
        const bpsSpeed = imageSize / dnldTime;
        const mbpsSpeed = bpsSpeed / 1024 / 1024;
        resolve(mbpsSpeed);
      });
    });
  for (let i = 0; i < 15; i++) {
    moveBar(width);
    const speed = (await calculateSpeed()) as number;
    estSpeed = estSpeed + speed;
    width++;
  }

  const finalEstSpeed = estSpeed / 15;
  if (finalEstSpeed < 3) {
    displayResult(lowBandwidthMsg);
  }
};
