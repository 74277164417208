import { displayMsg, displayResult } from "./DisplayMsg";
import { moveBar } from "./MoveBar";
import { micPermissionDeniedMsg, noMicDeviceMsg, micBusyMsg } from "./messages";

const constraints = {
  audio: true,
  video: false,
};

export const testMic = async (width: number) => {
  displayMsg("Testing Microphone...", "success");
  let micResult = "";

  const mediaDevices = navigator.mediaDevices;

  try {
    const stream = await mediaDevices.getUserMedia(constraints);
    if (stream.getAudioTracks().length > 0) {
      stream.getAudioTracks().forEach((track) => {
        track.stop();
      });
    } else {
      displayResult(noMicDeviceMsg);
    }
  } catch (error: any) {
    if (error.name === "NotReadableError") {
      micResult = micBusyMsg;
    } else if (error.name === "NotAllowedError") {
      micResult = micPermissionDeniedMsg;
    } else {
      micResult = noMicDeviceMsg;
    }
    displayResult(micResult);
  }
  moveBar(width);
};
