import "./App.css";
import { testCam } from "./Tests/CameraTest";
import { testMic } from "./Tests/MicTest";
import { testBrowser } from "./Tests/BrowserTest";
import { finalMsg } from "./Tests/FinalMsg";
import { appName, contHtmlId, logoImage } from "./Tests/constants";
import { moveBar } from "./Tests/MoveBar";
import { testBluetooth } from "./Tests/BluetoothTest";
import { testInternet } from "./Tests/InternetTest";

function App() {
  return (
    <div className="App">
      <header className="App-header" id="app">
        <div className="Header-div">
          <img className="Logo-image" src={logoImage} alt="PM Check Logo"></img>
          <span className="Header-title">{appName}</span>
        </div>
        <div id="mainDisplay" className="Main-display">
          <div className="First-div" id="firstDiv">
            <div className="Instructions-div" id="instructionsDiv">
              <span>
                This test will quickly check your devices to make sure:
              </span>
              <ol>
                <li>
                  Your patient monitoring device is functioning as expected.
                </li>
                <li>
                  Your web browser, microphone and camera are all compatible
                  with healthdirect Video Call.
                </li>
              </ol>
            </div>
            <button
              className="Start-button"
              id="startButton"
              onClick={startTest}
            >
              Start Test
            </button>
          </div>
          <div className="Progress-container" id="progressContainer">
            <div className="Progress-msg" id="progressMsg"></div>
            <div className="Outer-bar" id="outerBar">
              <div className="Progress-bar" id="progressBar"></div>
            </div>
          </div>
          <div id={contHtmlId} className="Summary-container"></div>
        </div>
        <div className="Footer-div">
          <img
            className="Footer-img"
            src="https://static.coviu.com/uathd/images/HD_VideoCall.svg"
            alt = "Healthdirect Video Call Logo"
          ></img>
          <span className="Footer-text">
            Need Help? Visit our{" "}
            <a href="https://help.vcc.healthdirect.org.au/remote-patient-monitoring">
              Help Centre
            </a>
          </span>
        </div>
      </header>
    </div>
  );
}

export const startTest = async () => {
  moveBar(5);
  const startButton = document.getElementById("firstDiv") as HTMLElement;
  const outerBar = document.getElementById("outerBar") as HTMLElement;

  startButton.style.display = "none";
  outerBar.style.display = "block";

  const browserRes = testBrowser(15);
  if (!browserRes) {
    await testCam(35);
    await testMic(55);
    await testBluetooth(75);
    await testInternet(85);
  }
  await finalMsg();
};
export default App;
